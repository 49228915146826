import { ChartConfigColorTheme } from 'gen/service/web'

export enum ThemeType {
  Gray = 'Gray',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedBackground = 'Red background',
  YellowBackground = 'Yellow background',
  GreenBackground = 'Green background',
  Blue = 'Blue',
  BlueBackground = 'Blue background',
  Purple = 'Purple',
  PurpleBackground = 'Purple background',
  Unchanged = 'Unchanged'
}

export const ThemeColorMap = new Proxy(
  {
    [ThemeType.Gray]: {
      label: 'Gray',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#131D2E',
        lineColor: '#D0D7DE',
        areaColor: '#E7EBEF'
      }
    },
    [ThemeType.Red]: {
      label: 'Red',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#D42B1C',
        lineColor: '#F27368',
        areaColor: '#FEF7F7'
      }
    },
    [ThemeType.Yellow]: {
      label: 'Yellow',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#E67802',
        lineColor: '#FFBA4D',
        areaColor: '#F2ECE8'
      }
    },
    [ThemeType.Green]: {
      label: 'Green',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#469415',
        lineColor: '#9CCE7D',
        areaColor: '#F8FCF6'
      }
    },
    [ThemeType.Blue]: {
      label: 'Blue',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#2E71DB',
        lineColor: '#568CE2',
        areaColor: '#CDDDF7'
      }
    },
    [ThemeType.Purple]: {
      label: 'Purple',
      value: {
        backgroundColor: '#FFFFFF',
        textColor: '#992EDB',
        lineColor: '#C07EE9',
        areaColor: '#D3A6F0'
      }
    },
    [ThemeType.RedBackground]: {
      label: 'Red background',
      value: {
        backgroundColor: '#ED3B2B',
        textColor: '#FFFFFF',
        lineColor: '#D42B1C',
        areaColor: '#F05B4D'
      }
    },
    [ThemeType.YellowBackground]: {
      label: 'Yellow background',
      value: {
        backgroundColor: '#FF9F05',
        textColor: '#FFFFFF',
        lineColor: '#E67802',
        areaColor: '#FFAE2D'
      }
    },
    [ThemeType.GreenBackground]: {
      label: 'Green background',
      value: {
        backgroundColor: '#58AD23',
        textColor: '#FFFFFF',
        lineColor: '#469415',
        areaColor: '#73BA46'
      }
    },
    [ThemeType.BlueBackground]: {
      label: 'Blue background',
      value: {
        backgroundColor: '#568CE2',
        textColor: '#FFFFFF',
        lineColor: '#0756D5',
        areaColor: '#2E71DB'
      }
    },
    [ThemeType.PurpleBackground]: {
      label: 'Purple background',
      value: {
        backgroundColor: '#992EDB',
        textColor: '#FFFFFF',
        lineColor: '#5B049C',
        areaColor: '#7006C0'
      }
    }
  },
  {
    get(target, prop, receiver) {
      if (prop === ThemeType.Unchanged) {
        return UnchangedColorTheme
      }
      if (!target[prop]) {
        return target[ThemeType.Gray]
      }
      return Reflect.get(target, prop, receiver)
    }
  }
)

export const DarkThemeColorMap = new Proxy(
  {
    [ThemeType.Gray]: {
      label: 'Gray',
      value: {
        backgroundColor: '#202020',
        textColor: '#E4E4E4',
        lineColor: '#757575',
        areaColor: '#5d6165'
      }
    },
    [ThemeType.Red]: {
      label: 'Red',
      value: {
        backgroundColor: '#202020',
        textColor: '#e35f4e',
        lineColor: '#F27368',
        areaColor: '#F2736860'
      }
    },
    [ThemeType.Yellow]: {
      label: 'Yellow',
      value: {
        backgroundColor: '#202020',
        textColor: '#E67802',
        lineColor: '#FFBA4D',
        areaColor: '#FFBA4D60'
      }
    },
    [ThemeType.Green]: {
      label: 'Green',
      value: {
        backgroundColor: '#202020',
        textColor: '#469415',
        lineColor: '#9CCE7D',
        areaColor: '#9CCE7D60'
      }
    },
    [ThemeType.Blue]: {
      label: 'Blue',
      value: {
        backgroundColor: '#202020',
        textColor: '#2E71DB',
        lineColor: '#568CE2',
        areaColor: '#CDDDF7'
      }
    },
    [ThemeType.Purple]: {
      label: 'Purple',
      value: {
        backgroundColor: '#202020',
        textColor: '#9a2ce3',
        lineColor: '#C07EE9',
        areaColor: '#D3A6F0'
      }
    },
    [ThemeType.RedBackground]: {
      label: 'Red background',
      value: {
        backgroundColor: '#ED3B2B',
        textColor: '#FFFFFF',
        lineColor: '#D42B1C',
        areaColor: '#F05B4D'
      }
    },
    [ThemeType.YellowBackground]: {
      label: 'Yellow background',
      value: {
        backgroundColor: '#FF9F05',
        textColor: '#FFFFFF',
        lineColor: '#E67802',
        areaColor: '#FFAE2D'
      }
    },
    [ThemeType.GreenBackground]: {
      label: 'Green background',
      value: {
        backgroundColor: '#58AD23',
        textColor: '#FFFFFF',
        lineColor: '#469415',
        areaColor: '#73BA46'
      }
    },
    [ThemeType.BlueBackground]: {
      label: 'Blue background',
      value: {
        backgroundColor: '#568CE2',
        textColor: '#FFFFFF',
        lineColor: '#0756D5',
        areaColor: '#2E71DB'
      }
    },
    [ThemeType.PurpleBackground]: {
      label: 'Purple background',
      value: {
        backgroundColor: '#992EDB',
        textColor: '#FFFFFF',
        lineColor: '#5B049C',
        areaColor: '#7006C0'
      }
    }
  },
  {
    get(target, prop, receiver) {
      if (prop === ThemeType.Unchanged) {
        return UnchangedColorTheme
      }
      if (!target[prop]) {
        return target[ThemeType.Gray]
      }
      return Reflect.get(target, prop, receiver)
    }
  }
)

export function getThemeColor(key: string, isDarkMode?: boolean) {
  const res = isDarkMode ? DarkThemeColorMap[key] : ThemeColorMap[key]
  return res
}

export const UnchangedColorTheme = {
  label: 'unchanged',
  value: {
    backgroundColor: 'transparent',
    textColor: 'inherit',
    lineColor: 'inherit',
    areaColor: 'inherit'
  }
}

const OBSOLETE_COLOR_THEMES = {
  [ThemeType.Gray]: {
    backgroundColor: '#F6F8FA',
    textColor: '#131D2E'
  },
  [ThemeType.Red]: {
    backgroundColor: 'transparent',
    textColor: '#E50567'
  },
  [ThemeType.Yellow]: {
    backgroundColor: 'transparent',
    textColor: '#FA920A'
  },
  [ThemeType.Green]: {
    backgroundColor: 'transparent',
    textColor: '#3BB083'
  },
  [ThemeType.RedBackground]: {
    backgroundColor: '#E50567',
    textColor: '#ffffff'
  },
  [ThemeType.YellowBackground]: {
    backgroundColor: '#FA920A',
    textColor: '#ffffff'
  },
  [ThemeType.GreenBackground]: {
    backgroundColor: '#3BB083',
    textColor: '#ffffff'
  }
}

export const upgradeTheme = (data: ChartConfigColorTheme, defaultTheme = ThemeType.Gray): ThemeType => {
  const { textColor, backgroundColor, themeType } = data
  if (themeType) {
    return themeType as ThemeType
  }

  let theme = defaultTheme

  Object.keys(OBSOLETE_COLOR_THEMES).some((key) => {
    const item = OBSOLETE_COLOR_THEMES[key]
    if (item.textColor === textColor && item.backgroundColor === backgroundColor) {
      theme = key as ThemeType
      return true
    }
  })

  return theme
}

export const AllThemeTypes = Object.values(ThemeType)
export const AllThemeColors = Object.values(ThemeColorMap)
