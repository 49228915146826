import { MetricsQueryResponseMetric, MetricsQueryResponseResult } from 'gen/service/observability'
import { ChartChartType, ChartConfigCalculation } from 'gen/service/web'
import { aliasTemplate } from './template'
import { last, maxBy, meanBy, minBy, sumBy, uniqBy } from 'lodash'
import { getChainName } from '@sentio/chain'
import { MetricsQueryResponseSample } from '../../gen/service/observability'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { shiftTimezone } from '../time'

dayjs.extend(utc)
dayjs.extend(timezone)

// for metrics with same name, find all the label keys that has distinct values
export function selectLabelKeys(metrics: MetricsQueryResponseMetric[]): string[] {
  const distinctValue = new Map<string, Set<string>>()
  for (const metric of metrics) {
    for (const [key, value] of Object.entries(metric?.labels || {})) {
      if (!distinctValue.has(key)) {
        distinctValue.set(key, new Set<string>([value]))
        continue
      }
      const v = distinctValue.get(key)
      v?.add(value)
    }
  }

  const res: string[] = []
  for (const [key, value] of distinctValue.entries()) {
    if (value.size > 1) {
      res.push(key)
    }
  }
  return res
}

export type SeriesData<T> = [T, number | null]

export interface Series<T> {
  name: string
  data: SeriesData<T>[]
  showSymbol: boolean
  type: 'line' | 'bar'
  areaStyle?: any
  lineStyle?: any
  stack?: string
  stackStrategy?: string
  emphasis?: any
  id: string
  xAxisIndex?: number
}

function getSeries(
  name: string | undefined,
  data: SeriesData<Date>[],
  chartType?: ChartChartType,
  showSymbol = false,
  id?: string
): Series<Date> {
  const ret: any = {
    id,
    name,
    data,
    showSymbol,
    emphasis: {
      focus: 'series'
    }
  }
  switch (chartType) {
    case ChartChartType.AREA:
      ret.type = 'line'
      ret.areaStyle = {}
      break
    case ChartChartType.BAR:
      ret.type = 'bar'
      ret.barMaxWidth = '30'
      break
    case ChartChartType.PIE:
      ret.type = 'pie'
      break
    case ChartChartType.LINE:
    default:
      ret.type = 'line'
      break
  }

  ret.animation = false
  // ret.sampling = 'lttb'
  // ret.progressive = 100
  // ret.progressiveThreshold = 100

  return ret
}

export function dateRangeOfSeries(series: Series<Date>[]): [Date, Date] {
  let min = new Date()
  let max = new Date(0)
  for (const s of series) {
    if (s.data.length > 0) {
      // data is sorted by timestamp
      const dmin = s.data[0][0] || min
      const dmax = s.data[s.data.length - 1][0] || max
      if (dmin < min) {
        min = dmin
      }
      if (dmax > max) {
        max = dmax
      }
    }
  }
  return [min, max]
}

const sortName = (x, y) =>
  x.localeCompare(y, undefined, {
    numeric: true
  })

export function timezoneShift(timestamp: string = '0', tz?: string) {
  const unix = dayjs.unix(parseInt(timestamp ?? '0'))
  return shiftTimezone(unix, tz)
}

export function computeSeries(
  results: MetricsQueryResponseResult[],
  chartType?: ChartChartType,
  calculation: ChartConfigCalculation = ChartConfigCalculation.ALL,
  showSymbol = false,
  idSuffix?: string,
  tz?: string
): {
  series: Series<Date>[]
  legend: string[]
  seriesToMetricLabels: { name: string; labels: MetricsQueryResponseMetric['labels'] }[]
} {
  const series: Series<Date>[] = []
  const legend: string[] = []
  const seriesToMetricLabels: {
    name: string // series name
    labels: MetricsQueryResponseMetric['labels'] // metric labels
    id?: string // metric id
  }[] = []

  // Step 1: group metrics with same name
  const nameToMetrics = new Map<string, MetricsQueryResponseMetric[]>()
  for (const result of results) {
    const samples = result.matrix?.samples || []
    for (const sample of samples) {
      const name = result.alias || sample?.metric?.displayName || '' // || sample.metric.name
      let metrics = nameToMetrics.get(name)
      if (!metrics) {
        metrics = []
        nameToMetrics.set(name, metrics)
      }
      if (sample.metric) {
        metrics.push(sample.metric)
      }
    }
  }

  // Step 2: compute list of labels for duplicated name metric to use in legend
  const nameToLabelKeys = new Map<string, string[]>()
  for (const [key, value] of nameToMetrics.entries()) {
    nameToLabelKeys.set(key, selectLabelKeys(value))
  }

  // Step 3: compute the data frontend need
  for (const result of results) {
    const alias = result.alias || ''
    if (result.error) {
      const name = `${alias || result.id} - error(${result.error})`
      series.push(getSeries(name, [], chartType, showSymbol))
      legend.push(name)
      continue
    }
    const samples = result.matrix?.samples || []
    if (samples.length === 0 && alias != '') {
      series.push(getSeries(alias, [], chartType, showSymbol))
      legend.push(alias)
      continue
    }

    for (const sample of samples) {
      const labels = { ...(sample?.metric?.labels || {}) }
      // replace chain id with chain name
      if (labels['chain']) {
        labels['chain'] = getChainName(labels['chain'])
      }
      let name = aliasTemplate(alias, labels) || sample?.metric?.displayName || '' // || sample.metric.name
      if (legend.includes(name)) {
        name = `${name} (${legend.length + 1})`
      }
      // TODO if the query has explicit labels, add them to the list as well
      const keysToUse = nameToLabelKeys.get(name) || []
      let attrs = ''
      for (const key of keysToUse) {
        const value = labels?.[key]
        if (value === undefined) {
          continue
        }
        if (attrs !== '') {
          attrs += ','
        }
        let k = key
        if (key === 'contract_name') {
          k = 'contract'
        }
        if (key === 'contract_address') {
          k = 'address'
        }
        attrs += k + ':' + value
      }
      if (attrs !== '') {
        name = name + '{' + attrs + '}'
      }

      let data: any[] = []
      const values = sample.values || []
      switch (calculation) {
        case ChartConfigCalculation.MEAN:
          if (values.length > 0) {
            const timestamp = last(values)?.timestamp || '0'
            data = [[timezoneShift(timestamp, tz), meanBy(values, (v) => v.value)]]
          }
          break
        case ChartConfigCalculation.TOTAL:
          if (values.length > 0) {
            const ts = last(values)?.timestamp || '0'
            data = [[timezoneShift(ts, tz), sumBy(values, (v) => v.value || 0)]]
          }
          break
        case ChartConfigCalculation.LAST:
          if (values.length > 0) {
            const v = last(values)
            data = [[timezoneShift(v?.timestamp, tz), v?.value]]
          }
          break
        case ChartConfigCalculation.FIRST:
          if (values.length > 0) {
            const v = values[0]
            data = [[timezoneShift(v.timestamp, tz), v.value]]
          }
          break
        case ChartConfigCalculation.MAX:
          if (values.length > 0) {
            const timestamp = last(values)?.timestamp || '0'
            data = [[timezoneShift(timestamp, tz), maxBy(values, (v) => v.value)?.value]]
          }
          break
        case ChartConfigCalculation.MIN:
          if (values.length > 0) {
            const timestamp = last(values)?.timestamp || '0'
            data = [[timezoneShift(timestamp, tz), minBy(values, (v) => v.value)?.value]]
          }
          break
        case ChartConfigCalculation.ALL:
        default:
          data = values.map((value) => {
            return [timezoneShift(value.timestamp, tz), value.value]
          })
      }
      series.push(getSeries(name, data, chartType, showSymbol, genSeriesId(sample, result.id, result.alias, idSuffix)))
      legend.push(name)
      seriesToMetricLabels.push({ name, labels: sample?.metric?.labels || {}, id: result.id })
    }
  }

  return {
    series: uniqBy(
      series.sort((x, y) => sortName(x.name, y.name)),
      (s) => s.id
    ),
    legend: legend.sort(sortName),
    seriesToMetricLabels: seriesToMetricLabels.sort((x, y) => sortName(x.name, y.name))
  }
}

export function genSeriesId(sample: MetricsQueryResponseSample, resultId?: string, alias?: string, idSuffix?: string) {
  // compute id by hash metric name & labels
  const labels = sample?.metric?.labels || {}
  const name = sample?.metric?.name || ''
  const labelKeys = Object.keys(labels).sort()
  const labelStrings = labelKeys.map((key) => `${key}_${labels[key]}`).join(',')
  const keyString = `${resultId}_${alias}_${name}_${labelStrings}`
  const hashedId = jenkinsOneAtATimeHash(keyString)
  if (idSuffix) {
    return `${hashedId}_${idSuffix}`
  }
  return hashedId
}

//Credits (modified code): Bob Jenkins (http://www.burtleburtle.net/bob/hash/doobs.html)
//See also: https://en.wikipedia.org/wiki/Jenkins_hash_function
//Takes a string of any size and returns an avalanching hash string of 8 hex characters.
function jenkinsOneAtATimeHash(keyString) {
  let hash = 0
  for (let charIndex = 0; charIndex < keyString.length; ++charIndex) {
    hash += keyString.charCodeAt(charIndex)
    hash += hash << 10
    hash ^= hash >> 6
  }
  hash += hash << 3
  hash ^= hash >> 11
  //4,294,967,295 is FFFFFFFF, the maximum 32 bit unsigned integer value, used here as a mask.
  return (((hash + (hash << 15)) & 4294967295) >>> 0).toString(16)
}
