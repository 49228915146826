import { ChartConfigValueConfig, ChartConfigValueFormatter } from 'gen/service/web'
import { defaults } from 'lodash'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import { ValueFormatters, ValueOptions } from './ValueOptions'
import { produce } from 'immer'
import { ChartConfigValueConfigStyle } from 'gen/service/web'

export interface ValueFormatter {
  label: string
  value: ChartConfigValueFormatter
}

interface Props {
  config?: ChartConfigValueConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigValueConfig) => void
  formatters?: ValueFormatter[]
}

export const defaultConfig: ChartConfigValueConfig = {
  valueFormatter: ChartConfigValueFormatter.NumberFormatter,
  showValueLabel: false,
  maxSignificantDigits: 3,
  dateFormat: 'LLL',
  mappingRules: [],
  style: ChartConfigValueConfigStyle.None,
  maxFractionDigits: 2
}

export const ValueControls = ({ config, defaultOpen, onChange, formatters }: Props) => {
  config = defaults(config || {}, defaultConfig)
  function toggleShowValueLabel(e) {
    config && onChange(produce(config, (draft) => void (draft.showValueLabel = e.target.checked)))
  }
  return (
    <DisclosurePanel
      title="Value Options"
      defaultOpen={defaultOpen}
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
    >
      <ValueOptions onChange={onChange} config={config} formatters={formatters} />

      <div className="sm:text-ilabel text-text-foreground mt-2 inline-flex items-center">
        <input
          type="checkbox"
          className="border-border-color mr-1 rounded"
          checked={config?.showValueLabel}
          onChange={toggleShowValueLabel}
        />
        Show value label
      </div>
    </DisclosurePanel>
  )
}

ValueControls.defaultProps = {
  formatters: ValueFormatters
}
