import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useMemo } from 'react'
import classNames from 'lib/classnames'
import { ChartConfigColorTheme } from 'gen/service/web'
import { upgradeTheme, ThemeType, ThemeColorMap, getThemeColor } from 'lib/colors/query-value-theme'
import { map } from 'lodash'
import { useDarkMode } from 'lib/util/use-dark-mode'

export interface ColorTheme {
  label: string
  value: {
    backgroundColor?: string
    textColor?: string
    themeType?: ThemeType
  }
}

interface Props {
  value?: ChartConfigColorTheme
  onChange: (value?: ColorTheme) => void
  defaultValue?: ColorTheme
}

function ColorItem({ selected, active, themeType }: { selected?: boolean; active?: boolean; themeType?: ThemeType }) {
  const isDarkMode = useDarkMode()
  const { label, value } = getThemeColor(themeType || ThemeType.Gray, isDarkMode)
  return (
    <div
      className={classNames(
        active ? 'ring-primary-500 ring-2' : '',
        'relative flex cursor-default select-none py-1 pl-2 pr-4'
      )}
      style={{ color: value.textColor, backgroundColor: value.backgroundColor }}
    >
      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block flex-1 truncate text-center')}>
        {label}
      </span>
      {selected ? (
        <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-2')}>
          <CheckIcon
            className={classNames('h-4 w-4', themeType?.includes('background') ? 'text-white' : 'text-primary')}
            aria-hidden="true"
          />
        </span>
      ) : null}
    </div>
  )
}

export function ColorSelect({ value, onChange, defaultValue }: Props) {
  const isDarkMode = useDarkMode()
  const colors = useMemo(() => {
    const result = map(ThemeColorMap, (item, key) => {
      const themeColor = getThemeColor(key)
      return {
        label: themeColor.label,
        value: {
          themeType: key as ThemeType,
          textColor: themeColor.value.textColor,
          backgroundColor: themeColor.value.backgroundColor
        }
      }
    })
    if (defaultValue) {
      return [defaultValue, ...result]
    }
    return result
  }, [defaultValue, isDarkMode])

  const val = useMemo(() => {
    if (value) {
      const theme = upgradeTheme(value, defaultValue?.value.themeType ?? ThemeType.Gray)
      return colors.find((c) => c.value.themeType === theme) || colors[0]
    } else {
      return colors[0]
    }
  }, [value, defaultValue?.value.themeType, colors])

  return (
    <Listbox value={val} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative h-full w-48">
            <Listbox.Button className="sm:text-ilabel dark:bg-sentio-gray-100 group relative flex h-full w-full cursor-default gap-2 rounded-r-md bg-white px-2 py-0.5 text-left shadow-sm">
              <div className="flex-1">
                <ColorItem themeType={val?.value?.themeType} />
              </div>
              <div className="pointer-events-none flex-shrink-0 self-center">
                <ChevronDownIcon
                  className="h-4 w-4 text-gray-600 group-hover:text-gray-500 group-active:text-gray-700"
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="text-ilabel sm:text-ilabel dark:bg-sentio-gray-100 absolute z-10 mt-1 max-h-60 w-full space-y-1 overflow-auto rounded-md bg-white px-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-100">
                {colors.map((c) => (
                  <Listbox.Option key={c.label} value={c}>
                    {({ selected, active }) => (
                      <ColorItem themeType={c?.value?.themeType} selected={selected} active={active} />
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
