import { PlusIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import classNames from 'lib/classnames'
import Button from '../../common/buttons/NewButton'
import { ChartConfigMappingRule } from 'gen/service/web'
import { produce } from 'immer'
import { ColorSelect } from 'components/common/select/ColorSelect'
import { ThemeType } from 'lib/colors/query-value-theme'

const operators = {
  '>': 'greater than',
  '>=': 'greater or equal',
  '==': 'equal',
  '!=': 'not equal',
  '<': 'less than',
  '<=': 'less or equal'
}

interface Props {
  rules: ChartConfigMappingRule[]
  onChange: (rules: ChartConfigMappingRule[]) => void
}

const defaultValue = {
  label: 'unchanged',
  value: { themeType: ThemeType.Unchanged, backgroundColor: 'transparent', textColor: 'inherit' }
}

export function ValueStringMapping({ rules, onChange }: Props) {
  const addRule = () => {
    onChange(
      produce(rules, (draft) => {
        draft = draft || []
        draft.push({
          comparison: '==',
          value: 0,
          text: ''
        })
      })
    )
  }

  function removeRule(index) {
    onChange(
      produce(rules, (draft) => {
        draft.splice(index, 1)
      })
    )
  }

  function changeRule(index: number, field: string, value: any) {
    onChange(
      produce(rules, (draft) => {
        draft[index][field] = value
      })
    )
  }

  return (
    <div className="flex flex-col gap-1 rounded-md py-2">
      {(rules || []).map((rule, index) => {
        return (
          <div key={index} className="border-border-color flex h-10 rounded border py-1 text-gray-500">
            <span className="sm:text-ilabel  inline-flex h-full items-center  px-3">If value is</span>
            <select
              value={rule.comparison}
              onChange={(e) => changeRule(index, 'comparison', e.target.value)}
              className="rounded-r-0 sm:text-ilabel inline-flex h-full items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 py-1  pl-4 pr-7 text-gray-500"
            >
              {Object.entries(operators).map(([op, display]) => {
                return (
                  <option key={op} value={op}>
                    is {display}
                  </option>
                )
              })}
            </select>
            <input
              type="text"
              name="value"
              id="value"
              className="w-30 rounded-l-0 sm:text-ilabel block h-full rounded-r-md  border border-gray-300  px-1 py-1 py-2 focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="0"
              value={rule.value}
              onChange={(e) => {
                changeRule(index, 'value', e.target.value)
              }}
            />
            <span className="sm:text-ilabel  inline-flex h-full items-center  rounded-none px-3">then show</span>
            <input
              type="text"
              name="text"
              id="text"
              className="w-30 sm:text-ilabel block h-full  rounded-md  border-gray-300 px-1 py-1 focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="text"
              value={rule.text}
              onChange={(e) => {
                changeRule(index, 'text', e.target.value)
              }}
            />
            <span className="sm:text-ilabel  inline-flex h-full items-center rounded-none px-3">also set color</span>
            <span className="focus-within:ring-primary-500 rounded border border-gray-300  px-0.5 focus-within:border-transparent focus-within:ring-2">
              <ColorSelect
                value={rule.colorTheme}
                defaultValue={defaultValue}
                onChange={(colorTheme) => {
                  changeRule(index, 'colorTheme', colorTheme?.value)
                }}
              />
            </span>
            <div className="flex-1"></div>
            <button type="button" className="mr-2" aria-label="remove" onClick={() => removeRule(index)}>
              <TrashIcon className={'icon text-gray-400'} aria-hidden="true" />
            </button>
          </div>
        )
      })}
      <Button type="button" role="secondary" className="mt-1 w-fit flex-none" aria-label="remove" onClick={addRule}>
        <PlusIcon className={classNames('h-5 w-5 text-gray-400')} aria-hidden="true" />
        Add Rule
      </Button>
    </div>
  )
}
