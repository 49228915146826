import { MetricsQueryResponse } from 'gen/service/observability'
import { valueFormatter } from './formatter'
import { aliasTemplate } from './template'
import { ChartConfig, ChartConfigCalculation } from 'gen/service/web'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import { meanBy, sumBy, first, last, startCase, maxBy, minBy } from 'lodash'
import { defaultConfig as defaultValueConfig } from 'components/charts/options/ValueControls'
import { getChainName } from '@sentio/chain'

const defaultColumnWidth = {
  time: 220,
  value: 100,
  name: 100,
  contract_address: 360,
  contract_name: 150,
  chain: 100
}

export function getColumnWidth(columnWidths: { [col: string]: number }, col: string): number | undefined {
  return (columnWidths && columnWidths[col]) || undefined
}

const timeCellRender = (info) => {
  if (info.getValue()) {
    try {
      return dayjs.unix(parseInt(info.getValue())).format('LLL')
    } catch (e) {
      // ignore error format of timestamp
    }
  }
  return ''
}

export type Column = {
  id: string
  header: string
  accessorKey: string
  cell: any
  size?: number
}

export function getTableData(data?: MetricsQueryResponse, config?: ChartConfig): { rows: any[]; columns: Column[] } {
  const rows = {}
  const columns: Column[] = []
  const columnSet = new Set()

  const addColumn = (id: string, colName: string, render?: any) => {
    if (!columnSet.has(id)) {
      columnSet.add(id)
      columns.push({
        id,
        header: colName,
        accessorKey: id,
        cell: render || ((info) => (info?.getValue() == null ? '' : String(info.getValue()))),
        size: getColumnWidth(config?.tableConfig?.columnWidths || {}, id)
      })
    }
  }

  function getGroupByTableData(data?: MetricsQueryResponse, config?: ChartConfig) {
    for (const r of data?.results || []) {
      for (const s of r.matrix?.samples || []) {
        const labels = s.metric?.labels || {}
        const groupBy = Object.entries(labels)
          .map((k, v) => `${k}:${v}`)
          .join(',')
        const row = rows[groupBy] || {}

        addColumn('__index', '#')

        for (const [k, v] of Object.entries(labels)) {
          const columnId = escapeColumnId(k)
          addColumn(columnId, startCase(k))
          row[columnId] = columnId == 'chain' ? getChainName(v) : v
        }

        const { columnName, columnId } = getColumnNameId(labels, r.alias, s.metric?.displayName)
        const valueConfig = config?.tableConfig?.valueConfigs?.[columnId] || defaultValueConfig
        addColumn(columnId, columnName, (info) => {
          const value = info.getValue()
          return valueFormatter(valueConfig)(value)
        })
        const calculation = config?.tableConfig?.calculations?.[columnId]
        switch (calculation) {
          case ChartConfigCalculation.MEAN:
            row[columnId] = meanBy(s.values, (d) => d.value)
            break
          case ChartConfigCalculation.MAX:
            row[columnId] = maxBy(s.values, (d) => d.value)?.value
            break
          case ChartConfigCalculation.MIN:
            row[columnId] = minBy(s.values, (d) => d.value)?.value
            break
          case ChartConfigCalculation.TOTAL:
            row[columnId] = sumBy(s.values, (d) => d.value || 0)
            break
          case ChartConfigCalculation.FIRST:
            row[columnId] = first(s.values)?.value
            break
          default:
          case ChartConfigCalculation.LAST:
            row[columnId] = last(s.values)?.value
            break
        }
        rows[groupBy] = row
      }
    }
    return { rows: Object.values(rows), columns }
  }

  function getPlainTableData(data?: MetricsQueryResponse, config?: ChartConfig) {
    const rows: any[] = []

    addColumn('name', 'name')
    addColumn('value', 'value', (info) => valueFormatter(config?.valueConfig)(info.getValue()))
    for (const r of data?.results || []) {
      for (const s of r.matrix?.samples || []) {
        const row = {}
        const labels = s?.metric?.labels || {}
        Object.entries(labels).forEach(([k, v]) => {
          addColumn(k, k)
          row[k] = k == 'chain' ? getChainName(v) : v
        })

        row['name'] = aliasTemplate(r.alias, labels) || s.metric?.displayName

        switch (config?.tableConfig?.calculation) {
          case ChartConfigCalculation.MEAN:
            rows.push({ ...row, value: meanBy(s.values, (d) => d.value) })
            break
          case ChartConfigCalculation.MAX:
            rows.push({ ...row, value: maxBy(s.values, (d) => d.value)?.value })
            break
          case ChartConfigCalculation.MIN:
            rows.push({ ...row, value: minBy(s.values, (d) => d.value)?.value })
            break
          case ChartConfigCalculation.TOTAL:
            rows.push({ ...row, value: sumBy(s.values, (d) => d.value || 0) })
            break
          case ChartConfigCalculation.FIRST:
            addColumn('time', 'time', timeCellRender)
            rows.push({ ...row, value: first(s.values)?.value, time: first(s.values)?.timestamp })
            break
          case ChartConfigCalculation.LAST:
            addColumn('time', 'time', timeCellRender)
            rows.push({ ...row, value: last(s.values)?.value, time: last(s.values)?.timestamp })
            break
          default:
            addColumn('time', 'time', timeCellRender)
            ;(s.values || []).forEach((v) => {
              rows.push({ ...row, value: v.value, time: v.timestamp })
            })
        }
      }
    }

    return { rows, columns }
  }

  if (config?.tableConfig?.showPlainData) {
    return getPlainTableData(data, config)
  } else {
    return getGroupByTableData(data, config)
  }
}

export function getColumnNameId(labels: { [p: string]: string }, alias?: string, displayName?: string) {
  const s = aliasTemplate(alias, labels) || startCase(displayName)
  const columnId = escapeColumnId(s)
  const columnName = s

  return { columnName, columnId }
}

function escapeColumnId(id: string) {
  return id.replace(/[\W_.]+/g, '_')
}
