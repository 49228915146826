import {
  ChartConfigMappingRule,
  ChartConfigValueConfig,
  ChartConfigValueConfigStyle,
  ChartConfigValueFormatter
} from '../../gen/service/web'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import currency from 'currency.js'
import { NumberFormat } from '../number'

dayjs.extend(LocalizedFormat)

export function valueFormatter(valueConfig?: ChartConfigValueConfig) {
  switch (valueConfig?.valueFormatter) {
    case ChartConfigValueFormatter.DateFormatter:
      return (value?: number | string | Date | null) => {
        if (value == null) {
          return ''
        }
        let d: dayjs.Dayjs
        if (typeof value === 'string') {
          d = dayjs(value)
        } else if (typeof value === 'number') {
          d =
            value < 10e13
              ? dayjs.unix(value) // seconds
              : value > 10e16
                ? dayjs(value / 1000) // microseconds ?
                : dayjs(value) // milliseconds
        } else {
          d = dayjs(value)
        }

        return d.format(valueConfig?.dateFormat || 'LLL')
      }
    case ChartConfigValueFormatter.StringFormatter:
      return (
        value: number | Date | string | undefined | null,
        ruleCallback?: (rule: ChartConfigMappingRule) => void
      ) => {
        if (value == null) {
          return ''
        }
        const results = evaluateRules(valueConfig?.mappingRules || [], value)

        if (results.length > 0) {
          const rule = results[results.length - 1]

          typeof ruleCallback === 'function' && ruleCallback(rule)
          return rule.text
        }

        return String(value)
      }
    case ChartConfigValueFormatter.NumberFormatter:
    default: {
      const options: Intl.NumberFormatOptions = {}
      switch (valueConfig?.style) {
        case ChartConfigValueConfigStyle.Standard:
          options.style = 'decimal'
          options.notation = 'standard'
          options.maximumFractionDigits = valueConfig?.maxFractionDigits ?? 3
          options.minimumFractionDigits = valueConfig?.maxFractionDigits ?? 0
          break
        case ChartConfigValueConfigStyle.Scientific:
          options.style = 'decimal'
          options.notation = 'scientific'
          options.maximumSignificantDigits = valueConfig?.maxSignificantDigits ?? 3
          break
        case ChartConfigValueConfigStyle.Percent:
          options.style = 'percent'
          options.maximumFractionDigits = valueConfig?.maxFractionDigits ?? 2
          options.minimumFractionDigits = valueConfig?.maxFractionDigits ?? 0
          break
        case ChartConfigValueConfigStyle.Currency:
          return (value?: number | string | null) => {
            if (value == null) {
              return ''
            }
            const v = currency(value, {
              symbol: valueConfig?.currencySymbol || '$',
              precision: valueConfig?.precision == null ? 2 : valueConfig.precision
            })
            return v.format()
          }
        case ChartConfigValueConfigStyle.Compact: {
          options.style = 'decimal'
          options.notation = 'compact'
          const maxSignificantDigits = valueConfig?.maxSignificantDigits
          if (maxSignificantDigits != null && maxSignificantDigits > 0) {
            options.maximumSignificantDigits = maxSignificantDigits
          }
          break
        }
        case ChartConfigValueConfigStyle.None:
        default:
          return (value?: number | string | null) => {
            return value?.toString()
          }
      }

      const NF = NumberFormat(options)
      return (value?: number | string | Date | null) => {
        if (value == null) {
          return ''
        }
        if (typeof value === 'string') {
          value = parseFloat(value)
        } else if (value instanceof Date) {
          value = value.getTime()
        }
        return NF.format(value)
      }
    }
  }
}

export function evaluateRules(rules: ChartConfigMappingRule[], value: any) {
  return rules.filter((rule) => matchRule(rule, value))
}

function matchRule(rule: ChartConfigMappingRule, value: any) {
  const val = rule.value || 0
  switch (rule.comparison) {
    case '==':
      return value === val
    case '!=':
      return value !== val
    case '>':
      return value > val
    case '>=':
      return value >= val
    case '<':
      return value < val
    case '<=':
      return value <= val
    default:
      return false
  }
}
