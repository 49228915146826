import { produce } from 'immer'
import { ChartConfigValueConfig, ChartConfigValueConfigStyle, ChartConfigValueFormatter } from 'gen/service/web'
import { ValueStringMapping } from './ValueStringMapping'
import { ComboInput } from '../../common/input/ComboInput'

export interface ValueFormatter {
  label: string
  value: ChartConfigValueFormatter
}

export const ValueFormatters = [
  { label: 'Number', value: ChartConfigValueFormatter.NumberFormatter },
  { label: 'Date', value: ChartConfigValueFormatter.DateFormatter },
  { label: 'String', value: ChartConfigValueFormatter.StringFormatter }
]

interface Props {
  config: ChartConfigValueConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigValueConfig) => void
  formatters: ValueFormatter[]
}

export const defaultConfig: ChartConfigValueConfig = {
  valueFormatter: ChartConfigValueFormatter.NumberFormatter,
  showValueLabel: false,
  maxSignificantDigits: 3,
  dateFormat: 'LLL',
  mappingRules: [],
  style: ChartConfigValueConfigStyle.None
}

const dateFormats = [
  { label: 'Localized format', value: 'LLL' },
  { label: 'ISO String', value: 'YYYY-MM-DDTHH:mm:ss.sssZ' }
]

const CurrencySymbols = [
  { label: 'USD', value: '$' },
  { label: 'EUR', value: '€' },
  { label: 'GBP', value: '£' },
  { label: 'CNY or JPY', value: '¥' },
  { label: 'BTC', value: 'Ƀ' },
  { label: 'ETH', value: 'Ξ' }
]

export const ValueOptions = ({ config, onChange, formatters }: Props) => {
  function onChangeDateFormat(f) {
    onChange(produce(config, (draft) => void (draft.dateFormat = f)))
  }
  function onChangeFormatter(f) {
    onChange(produce(config, (draft) => void (draft.valueFormatter = f)))
  }
  function onChangeSymbol(symbol) {
    onChange(produce(config, (draft) => void (draft.currencySymbol = symbol)))
  }
  function onStyleChange(notation: ChartConfigValueConfigStyle) {
    onChange(
      produce(config, (draft) => {
        draft.style = notation
      })
    )
  }
  function onDigitsChange(value: string, option: string) {
    onChange(
      produce(config, (draft) => {
        if (value) {
          const maxSignificantDigits = parseInt(value)
          if (maxSignificantDigits >= 0 && maxSignificantDigits <= 20) {
            draft[option] = maxSignificantDigits
          }
        } else {
          delete draft[option]
        }
      })
    )
  }

  function onMappingRulesChange(rules) {
    onChange(produce(config, (draft) => void (draft.mappingRules = rules)))
  }

  function numberAddons(style: ChartConfigValueConfigStyle) {
    switch (style) {
      case ChartConfigValueConfigStyle.None:
        return (
          <>
            <span className="sm:text-ilabel inline-flex items-center border border-gray-300  bg-gray-50 px-3 text-gray-500">
              Fraction Digits
            </span>
            <input
              disabled
              className="focus:border-primary-500 sm:text-ilabel min-w-[5rem]  rounded-r-md border border-l-0 border-gray-300  py-1"
              value={''}
            />
          </>
        )
      case ChartConfigValueConfigStyle.Percent:
      case ChartConfigValueConfigStyle.Standard:
        return (
          <>
            <span className="sm:text-ilabel inline-flex items-center border border-gray-300  bg-gray-50 px-3 text-gray-500">
              Fraction Digits
            </span>
            <input
              type="number"
              min={0}
              max={20}
              className="focus:border-primary-500 sm:text-ilabel min-w-[5rem]  rounded-r-md border border-l-0 border-gray-300  py-1"
              value={config.maxFractionDigits}
              placeholder={'0-20'}
              onChange={(e) => onDigitsChange(e.target.value, 'maxFractionDigits')}
            />
          </>
        )
      case ChartConfigValueConfigStyle.Currency:
        return (
          <>
            <span className="sm:text-ilabel inline-flex items-center border border-r-0 border-gray-300  bg-gray-50 px-3 text-gray-500">
              Symbol
            </span>
            <div className="w-28 ">
              <ComboInput
                onChange={onChangeSymbol}
                options={CurrencySymbols.map((s) => s.value)}
                displayFn={(s) => {
                  const name = CurrencySymbols.find((c) => c.value === s)?.label
                  return `${name} (${s})`
                }}
                placeholder={'$'}
                value={config?.currencySymbol}
              />
            </div>
            <span className="sm:text-ilabel inline-flex items-center border border-gray-300  bg-gray-50 px-3 text-gray-500">
              Precision
            </span>
            <input
              type="number"
              min={0}
              max={20}
              className="focus:border-primary-500 sm:text-ilabel min-w-[5rem]  rounded-r-md border border-l-0 border-gray-300  py-1"
              value={config.precision}
              defaultValue={2}
              placeholder={'0-20'}
              onChange={(e) => onDigitsChange(e.target.value, 'precision')}
            />
          </>
        )
      default:
        return (
          <>
            <span className="sm:text-ilabel inline-flex items-center border border-gray-300  bg-gray-50 px-3 text-gray-500">
              Max significant digits
            </span>
            <input
              type="number"
              min={1}
              max={21}
              className="focus:border-primary-500 sm:text-ilabel min-w-[5rem] rounded-r-md border border-l-0 border-gray-300  py-1"
              value={config.maxSignificantDigits}
              placeholder={'1-21'}
              onChange={(e) => onDigitsChange(e.target.value, 'maxSignificantDigits')}
            />
          </>
        )
    }
  }

  return (
    <div className="flex flex-col">
      <div>
        <div className="flex">
          <span className="sm:text-ilabel inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500">
            Value formatter
          </span>
          <select
            value={config.valueFormatter}
            className="sm:text-ilabel inline-flex items-center border border-l-0 border-gray-300  py-1.5 pl-4  pr-7 text-gray-500"
            onChange={(e) => onChangeFormatter(e.target.value as ChartConfigValueFormatter)}
          >
            {formatters.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
          {config.valueFormatter == ChartConfigValueFormatter.NumberFormatter && (
            <>
              <span className="sm:text-ilabel inline-flex items-center border border-l-0 border-r-0 border-gray-300  bg-gray-50 px-3 text-gray-500">
                Style
              </span>
              <select
                value={config.style}
                className="sm:text-ilabel inline-flex items-center border border-r-0 border-gray-300  py-1  pl-4  pr-7 text-gray-500"
                onChange={(e) => onStyleChange(e.target.value as ChartConfigValueConfigStyle)}
              >
                <option value={ChartConfigValueConfigStyle.None}>None</option>
                <option value={ChartConfigValueConfigStyle.Compact}>Compact</option>
                <option value={ChartConfigValueConfigStyle.Standard}>Standard</option>
                <option value={ChartConfigValueConfigStyle.Scientific}>Scientific</option>
                <option value={ChartConfigValueConfigStyle.Percent}>Percent</option>
                <option value={ChartConfigValueConfigStyle.Currency}>Currency</option>
              </select>
              {config.style && numberAddons(config.style)}
            </>
          )}
          {config.valueFormatter == ChartConfigValueFormatter.DateFormatter && (
            <>
              <span className="sm:text-ilabel inline-flex items-center border border-l-0 border-gray-300  bg-gray-50 px-3 text-gray-500">
                Date format
              </span>
              <select
                value={config.dateFormat}
                className="sm:text-ilabel inline-flex items-center rounded-r-md border border-l-0 border-gray-300  py-1  pl-4  pr-7 text-gray-500"
                onChange={(e) => onChangeDateFormat(e.target.value)}
              >
                {dateFormats.map((d) => {
                  return (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  )
                })}
              </select>
            </>
          )}
        </div>
      </div>
      {config.valueFormatter == ChartConfigValueFormatter.StringFormatter && (
        <ValueStringMapping rules={config.mappingRules || []} onChange={onMappingRulesChange} />
      )}
    </div>
  )
}

ValueOptions.defaultProps = {
  formatters: ValueFormatters
}
