const regex = /{{([\s\S]+?)}}/g

export function aliasTemplate(alias: string | undefined | null, labels: { [k: string]: string }) {
  if (alias) {
    try {
      labels = sanitizeLabels(labels)
      return alias.replace(regex, (_, m1) => {
        const value = labels[m1.trim()]
        return value == null ? `undefined` : value
      })
    } catch (e) {
      return alias
    }
  }
}

export function sanitizeLabels(labels: { [k: string]: string }) {
  const result = {}
  for (const k in labels) {
    switch (k) {
      case 'contract_name':
        result['contract'] = labels[k]
        break
      case 'contract_address':
        result['address'] = labels[k]
        break
    }
    result[k] = labels[k]
  }
  return result
}
