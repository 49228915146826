import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ChartConfigDirection, ChartConfigCalculation } from 'gen/service/web'
import { produce } from 'immer'
import { ChartConfigQueryValueConfig } from 'gen/service/web'
import { defaults } from 'lodash'
import { ColorSelect } from 'components/common/select/ColorSelect'
import { ThemeType } from 'lib/colors/query-value-theme'

interface Props {
  config?: ChartConfigQueryValueConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigQueryValueConfig) => void
}

export const defaultConfig = {
  calculation: ChartConfigCalculation.LAST,
  colorTheme: {
    themeType: ThemeType.Gray
  },
  showBackgroundChart: false
}

const CalculationItems = [
  { label: 'Last', value: ChartConfigCalculation.LAST },
  { label: 'First', value: ChartConfigCalculation.FIRST },
  { label: 'Total', value: ChartConfigCalculation.TOTAL },
  { label: 'Mean', value: ChartConfigCalculation.MEAN },
  { label: 'Max', value: ChartConfigCalculation.MAX },
  { label: 'Min', value: ChartConfigCalculation.MIN }
]

export function QueryValueControls({ config, defaultOpen, onChange }: Props) {
  config = defaults(config, defaultConfig)

  function onCalculationChange(cal) {
    config && onChange(produce(config, (draft) => void (draft.calculation = cal)))
  }

  function onSeriesCalculationChange(cal) {
    config && onChange(produce(config, (draft) => void (draft.seriesCalculation = cal)))
  }

  function onSelectColor(c) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.colorTheme = c.value
        })
      )
  }

  function toggleShowBackgroundChart() {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.showBackgroundChart = !draft.showBackgroundChart
        })
      )
  }

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className="dark:bg-sentio-gray-100 border-border-color w-full rounded border bg-white">
          <Disclosure.Button className="text-ilabel text-text-foreground  flex w-full px-4 py-2 text-left font-medium hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
            <ChevronRightIcon className={`${open ? 'rotate-90 transform' : ''} h-5 w-5 text-inherit`} />
            <span>Query Value Options</span>
          </Disclosure.Button>
          <Disclosure.Panel className="flex items-center gap-4 p-2">
            <div className="flex h-8 rounded-md shadow-sm">
              <span className="sm:text-ilabel inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500">
                For each series, calculate the
              </span>
              <select
                value={config?.calculation}
                className="sm:text-ilabel inline-flex items-center border border-x-0 border-gray-300  py-0.5  pl-4 pr-7 text-gray-500"
                onChange={(e) => onCalculationChange(e.target.value as ChartConfigDirection)}
              >
                {CalculationItems.map((d) => {
                  return (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  )
                })}
              </select>
              <span className="sm:text-ilabel inline-flex items-center border border-gray-300  bg-gray-50 px-3 text-gray-500">
                value, then show the
              </span>
              <select
                value={config?.seriesCalculation}
                className="sm:text-ilabel inline-flex items-center border border-x-0 border-gray-300  py-0.5  pl-4 pr-7 text-gray-500"
                onChange={(e) => onSeriesCalculationChange(e.target.value as ChartConfigDirection)}
              >
                {CalculationItems.map((d) => {
                  return (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  )
                })}
              </select>
              <span className="sm:text-ilabel inline-flex items-center rounded-r-md border border-gray-300  bg-gray-50 px-3 text-gray-500">
                value of multiple series
              </span>
            </div>

            <div className="focus-within:ring-primary-500 divide-x-gray-300 flex h-8 divide-x rounded-md border border-gray-300  shadow-sm focus-within:border-transparent focus-within:ring-2">
              <span className="sm:text-ilabel inline-flex items-center rounded-l-md bg-gray-50 px-3 text-gray-500">
                Color theme
              </span>
              <ColorSelect value={config?.colorTheme} onChange={onSelectColor} />
            </div>
            <div className="sm:text-ilabel inline-flex items-center text-gray-500">
              <input
                type="checkbox"
                className="mr-1"
                checked={config?.showBackgroundChart}
                onChange={toggleShowBackgroundChart}
              />
              Show background chart
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
