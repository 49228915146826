import { ChartConfigBarGaugeConfig, ChartConfigDirection, ChartConfigCalculation } from 'gen/service/web'
import { produce } from 'immer'
import { ChartConfigSortBy } from 'gen/service/web'
import { defaults } from 'lodash'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'

interface Props {
  config?: ChartConfigBarGaugeConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigBarGaugeConfig) => void
}

export const defaultConfig = {
  direction: ChartConfigDirection.HORIZONTAL,
  calculation: ChartConfigCalculation.LAST,
  sort: {
    sortBy: ChartConfigSortBy.ByName,
    orderDesc: true
  }
}

const directionItems = [
  { label: 'Horizontal', value: ChartConfigDirection.HORIZONTAL },
  { label: 'Vertical', value: ChartConfigDirection.VERTICAL }
]

const CalculationItems = [
  { label: 'Last', value: ChartConfigCalculation.LAST },
  { label: 'First', value: ChartConfigCalculation.FIRST },
  { label: 'Total', value: ChartConfigCalculation.TOTAL },
  { label: 'Mean', value: ChartConfigCalculation.MEAN },
  { label: 'Max', value: ChartConfigCalculation.MAX },
  { label: 'Min', value: ChartConfigCalculation.MIN }
]

const sortByItems = [
  { label: 'Name', value: ChartConfigSortBy.ByName },
  { label: 'Value', value: ChartConfigSortBy.ByValue }
]

const orderItems = [
  { label: 'Ascendant', value: 'false' },
  { label: 'Descendant', value: 'true' }
]

export function BarGaugeControls({ config, defaultOpen, onChange }: Props) {
  config = defaults(config, defaultConfig)

  function onCalculationChange(cal) {
    config && onChange(produce(config, (draft) => void (draft.calculation = cal)))
  }

  function onDirectionChange(dir) {
    config && onChange(produce(config, (draft) => void (draft.direction = dir)))
  }

  function onOrderChange(orderDesc) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.sort = draft.sort || {}
          draft.sort.orderDesc = orderDesc
        })
      )
  }

  function onSortByChange(sortBy) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.sort = draft.sort || {}
          draft.sort.sortBy = sortBy
        })
      )
  }

  return (
    <DisclosurePanel
      title="Bar Gauge Options"
      defaultOpen={defaultOpen}
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
    >
      <div className="flex items-center gap-4 p-2">
        <div className="f mt-1 flex  rounded-md shadow-sm">
          <span className="sm:text-ilabel inline-flex items-center rounded-l-md border  border-gray-300 bg-gray-50 px-3 text-gray-500">
            Direction
          </span>
          <select
            value={config.direction}
            className="sm:text-ilabel inline-flex items-center rounded-r-md border border-l-0 border-gray-300  pl-4   pr-7 text-gray-500"
            onChange={(e) => onDirectionChange(e.target.value as ChartConfigDirection)}
          >
            {directionItems.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
        </div>

        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="sm:text-ilabel inline-flex items-center rounded-l-md border  border-gray-300 bg-gray-50 px-3 text-gray-500">
            Calculation
          </span>
          <select
            value={config.calculation}
            className="sm:text-ilabel inline-flex items-center rounded-r-md border border-l-0 border-gray-300  pl-4   pr-7 text-gray-500"
            onChange={(e) => onCalculationChange(e.target.value as ChartConfigDirection)}
          >
            {CalculationItems.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
        </div>

        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="sm:text-ilabel inline-flex items-center rounded-l-md border  border-gray-300 bg-gray-50 px-3 text-gray-500">
            Sort by
          </span>
          <select
            value={config?.sort?.sortBy}
            className="sm:text-ilabel inline-flex items-center border border-l-0 border-gray-300    pl-4   pr-7 text-gray-500"
            onChange={(e) => onSortByChange(e.target.value as ChartConfigDirection)}
          >
            {sortByItems.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
          <select
            value={config?.sort?.orderDesc + ''}
            className="sm:text-ilabel inline-flex items-center rounded-r-md border border-l-0 border-gray-300  pl-4   pr-7 text-gray-500"
            onChange={(e) => onOrderChange(e.target.value === 'true')}
          >
            {orderItems.map((d) => {
              return (
                <option key={d.label} value={d.value + ''}>
                  {d.label}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    </DisclosurePanel>
  )
}
